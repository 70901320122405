import { useCallback } from 'react';
import { UrlModification } from '../../contexts/routing/UrlModificationsContext';
import { toRouterOptimisticKey } from './useRouterValueOptimistic';
import { useOptimisticValueUtils } from '../../contexts/OptimisticValueContext';

export const useRouterModifyOptimistic = () => {
  const { updateOptimisticValue } = useOptimisticValueUtils();

  return useCallback(
    (
      ...modifications: Pick<UrlModification, 'name' | 'location' | 'value'>[]
    ) => {
      modifications.forEach(({ name, location, value }) => {
        updateOptimisticValue(
          toRouterOptimisticKey(name, location),
          value || null,
        );
      });
    },
    [updateOptimisticValue],
  );
};
