import { useCallback } from 'react';
import { useGlobalComponentsContext } from '../../contexts/GlobalComponentsContext';
import { WizardOptions } from '../wizard/createUseWizard';
import { useRedirectCallback } from '../useRedirectCallback';
import { Identifiable } from '../../../functions/src/types/Identifiable';
import {
  NotificationSnackbarCountdown,
  NotificationSnackbarCountdownProps,
} from '../../components/NotificationSnackbarCountdown';

export type UseSnackbarCountdownParams = Identifiable & {
  options?: WizardOptions;
};

export const useSnackbarCountdown = ({
  id,
  options = { closeOnRouteChange: true },
}: UseSnackbarCountdownParams) => {
  const { union, remove } = useGlobalComponentsContext();

  const close = useCallback(() => {
    remove(id);
  }, [id, remove]);

  useRedirectCallback(options.closeOnRouteChange ? close : undefined);

  const open = useCallback(
    ({
      message,
      onTimeout,
      ...rest
    }: NotificationSnackbarCountdownProps & { onTimeout?: () => void }) => {
      union(
        id,
        <NotificationSnackbarCountdown
          open
          message={message}
          onClose={(_, reason) => {
            if (reason === 'timeout') {
              return onTimeout?.();
            }
            close();
          }}
          ClickAwayListenerProps={{ mouseEvent: false }}
          disableWindowBlurListener
          {...rest}
        />,
      );
    },
    [close, id, union],
  );

  return { open, close };
};
