import { memo } from '../util/memo';
import Snackbar, { SnackbarProps } from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useMemo } from 'react';
import { CountdownTimer, CountdownTimerProps } from './CountdownTimer';
import { useTheme } from '@mui/material/styles';
import { withOpacity } from '../util/styles/withOpacity';
import { SlideTransitionDown } from './transitions/SlideTransition';
import { ONE_SECOND_MILLIS } from '../../functions/src/util/conversions';

export type NotificationSnackbarCountdownProps = SnackbarProps &
  Pick<CountdownTimerProps, 'countdownMs'>;

const NotificationSnackbarCountdownUnmemoized = ({
  countdownMs,
  onClose,
  message,
  ...props
}: NotificationSnackbarCountdownProps) => {
  const theme = useTheme();

  const messageCountdown = useMemo(() => {
    return (
      <Stack direction="row" alignItems="center" spacing={4}>
        <CountdownTimer
          countdownMs={countdownMs}
          strokeBgColor={withOpacity(
            theme.palette.background.elevation[24],
            0.5,
          )}
          strokeColor={theme.palette.text.primary}
          strokeWidth={2}
          size={28}
        />
        <Typography variant="subtitle1" color="text.primary">
          {message}
        </Typography>
      </Stack>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdownMs, message]);

  return (
    <Snackbar
      TransitionComponent={SlideTransitionDown}
      autoHideDuration={countdownMs + ONE_SECOND_MILLIS}
      message={messageCountdown}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      sx={{
        zIndex: theme.zIndex.appBar + 10,
      }}
      {...props}
    />
  );
};

export const NotificationSnackbarCountdown = memo(
  NotificationSnackbarCountdownUnmemoized,
);
