import { FC, useMemo } from 'react';
import { memo } from '../../../util/memo';
import { useEditable } from '../../../contexts/organizer/EditableStepsContext';
import { useAuth } from '../../../contexts/AuthContext';
import { ImageInput, ImageInputProps } from './ImageInput';
import { UGC_STORAGE_DIRECTORY } from '../../../util/edit/uploadFile';

export type EventImageInputProps = Omit<ImageInputProps, 'storagePath'> & {
  fieldName: string;
};

const EventImageInputUnmemoized: FC<EventImageInputProps> = ({
  fieldName,
  ...props
}) => {
  const { uid } = useAuth();
  const { document } = useEditable();
  const eventStoragePath = useMemo(() => {
    if (!uid || !document) {
      return;
    }
    return `${UGC_STORAGE_DIRECTORY}/${uid}/${document.id}/${fieldName}`;
  }, [document, fieldName, uid]);
  return <ImageInput storagePath={eventStoragePath} {...props} />;
};
export const EventImageInput = memo(EventImageInputUnmemoized);
