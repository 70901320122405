import { memo } from '../util/memo';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/material/styles';

export type CountdownTimerProps = {
  countdownMs: number;
  strokeBgColor: string;
  strokeColor: string;
  strokeWidth: number;
  size: number;
  sx?: SxProps;
};

const CountdownTimerUnmemoized = ({
  countdownMs,
  strokeBgColor,
  strokeColor,
  strokeWidth,
  size,
  sx = {},
}: CountdownTimerProps) => {
  const [progress, setProgress] = useState(1);
  const [isPlaying, setIsPlaying] = useState(true);

  const radius = size / 2;
  const circumference = size * Math.PI;
  const remainingMs = Math.max(0, progress * countdownMs);
  const countdown = Math.floor(remainingMs / 1000);
  const strokeDashoffset = circumference * progress;

  useEffect(() => {
    let startTime: number | null = null;
    let animationFrameId: number;

    const animate = async (currentTime: number) => {
      if (!startTime) {
        startTime = currentTime;
      }

      const elapsed = currentTime - startTime;
      const remaining = countdownMs - elapsed;

      const newProgress = Math.min(1, Math.max(0, remaining / countdownMs));
      setProgress(newProgress);

      if (elapsed < countdownMs) {
        animationFrameId = requestAnimationFrame(animate);
      } else {
        setIsPlaying(false);
        setProgress(0);
      }
    };

    if (!isPlaying) {
      return;
    }
    animationFrameId = requestAnimationFrame(animate);

    return () => {
      if (!animationFrameId) {
        return;
      }
      cancelAnimationFrame(animationFrameId);
    };
  }, [countdownMs, isPlaying]);

  return (
    <Box
      sx={{
        position: 'relative',
        width: size,
        height: size,
        ...sx,
      }}
    >
      <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
        {/* Background circle */}
        <circle
          cx={radius}
          cy={radius}
          r={radius - strokeWidth / 2}
          fill="none"
          stroke={strokeBgColor}
          strokeWidth={strokeWidth}
        />

        {/* Progress circle */}
        <circle
          cx={radius}
          cy={radius}
          r={radius - strokeWidth / 2}
          fill="none"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          transform={`rotate(-90 ${radius} ${radius})`}
        />

        {/* Centered text */}
        <text
          x="50%"
          y="50%"
          textAnchor="middle"
          dominantBaseline="central"
          fill={strokeColor}
          fontSize={size * 0.5}
          fontWeight="bold"
        >
          {countdown}
        </text>
      </svg>
    </Box>
  );
};
export const CountdownTimer = memo(CountdownTimerUnmemoized);
